<template>
	<div
		ref="dialogHistory"
		class="dialog-history">
		<PageChatCurrentStage />

		<div
			ref="dialogHistory"
			class="dialog-history__container">
			<template
				v-for="(message, index) in messages"
				:key="message.id">
				<PageChatLabelDate
					v-if="isNewDayManagerMessage(index, messages)"
					:date="useDateFormat(message.created)" />

				<ManagerMessage
					:message="message"
					:is-same-sender="isPreviousMessageFromSameOrg(index, messages) && !isNewDayManagerMessage(index, messages)" />
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
	import ManagerMessage from "~/components/page/chat/manager-message/manager-message.vue";
	import { isNewDayManagerMessage } from "@/utils/is-new-day-message";
	import { isPreviousMessageFromSameOrg } from "~/utils/is-prev-message-from-same-org";
	import { useDateFormat } from "~/composables/useDateFormat";

	const { messages } = defineProps<{ messages: IOrgMessage[] }>();

	const dialogHistory = ref();

	watch(
		() => messages,
		() => {
			setTimeout(() => {
				if (!dialogHistory.value) return;

				dialogHistory.value.scrollTo({ top: dialogHistory.value.scrollHeight });
			}, 100);
		},
	);
</script>

<style scoped lang="scss">
	@import "dialog-history";
</style>
