<template>
	<div
		v-if="roomStore.getRoom && getStageLabel(roomStore.getRoom.stage)"
		class="current-stage">
		{{ getStageLabel(roomStore.getRoom.stage) }}
	</div>
</template>

<script setup lang="ts">
	import { getStageLabel } from "~/utils/get-stage-label";

	const { roomStore } = useStores();
</script>

<style scoped lang="scss">
	@import "current-stage";
</style>
