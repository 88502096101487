<template>
	<div :class="['manager-message', { 'manager-message--same-sender': isSameSender }]">
		<UserAvatar
			v-if="!isSameSender"
			class="manager-message__avatar"
			:key="message.organizer"
			:user-id="message.organizer"
			:full-name="message.organizer_name"
			:size="'xl'"
			:border="true" />

		<div class="manager-message__content">
			<div class="manager-message__header">
				<div
					v-if="!isSameSender"
					class="manager-message__info">
					<p class="manager-message__name">
						{{ message.organizer_name }}
					</p>

					<span
						v-if="message.agent_type.name"
						class="manager-message__agent-type">
						{{ message.agent_type.name }}
					</span>
				</div>

				<div class="manager-message__meta">
					<p class="manager-message__time">{{ useDateFormat(message.created, "HH:mm") }}</p>

					<CheckmarksReading :read="!!messageReaded" />
				</div>
			</div>

			<p class="manager-message__text">
				{{ message.message }}
			</p>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { useDateFormat } from "@/composables/useDateFormat";
	import { readOrgMessage } from "~/utils/read-org-message";

	const { isSameSender = false, message } = defineProps<{ message: IOrgMessage; isSameSender?: boolean }>();

	const messageReaded: Ref<null | string> = useState(`message-${message.id}-readed`, () => message.readed ?? null);

	readOrgMessage(message, messageReaded);

	watchEffect(() => {
		messageReaded.value = message.readed ?? null;
	});
</script>

<style lang="scss">
	@import "manager-message";
</style>
