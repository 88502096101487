import { api } from "~/api/api";
import { useDateFormat } from "~/composables/useDateFormat";

export async function readOrgMessage(message: IOrgMessage, var_message_readed: Ref<string | null>) {
	if (message.organizer === useUser().userId || message.readed) return;

	try {
		const readMessage = await useOFetch<IApiResponsePayload<IOrgReadMessageRes>>(api.roomMessageRead(message.id), {
			method: "POST",
		});

		if (readMessage.data.result) {
			var_message_readed.value = useDateFormat(new Date(), "YYYY-MM-DD HH:mm:ss");
		} else {
			console.error(readMessage.data.message);
		}
	} catch (e) {
		console.error(e);
	}
}
